import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // To detect navigation
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import WorkIcon from '@mui/icons-material/Work';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useUserOrgs from '../../hooks/useUserOrgs';
import OrgMemberRoles from './OrgMemberRoles';
import IOrganization from '../../interfaces/Organization';
import AddUsersToOrgButton from '../AddUsersToOrgButton';
import MemberActions from '../MemberActions';

interface Props {
  user_id: string;
}

const UserOrgsTable = ({ user_id }: Props) => {
  const { orgs, isLoading, error, mutate } = useUserOrgs(user_id);
  const location = useLocation(); // Detects navigation changes

  // useEffect to trigger refetch when the component mounts or when the user navigates to the page
  useEffect(() => {
    // Trigger re-fetch when the component mounts or location changes
    mutate(); // Re-fetch the organizations
  }, [location, mutate]); // Run effect when location changes

  if (isLoading) {
    return <p> Loading</p>;
  }
  if (error != null) {
    console.log(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Card>
      <CardHeader
        avatar={<WorkIcon />}
        title="Organizations"
        action={<AddUsersToOrgButton users={[user_id]} mutation={mutate} />}
      />
      <Divider />
      <CardContent>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>CUI UUID</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orgs.map((org: IOrganization) => (
                <TableRow key={org.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {org.display_name}
                  </TableCell>
                  <TableCell>{org.metadata?.CUI_ORG_UUID}</TableCell>
                  <TableCell>
                    <OrgMemberRoles user_id={user_id} org_id={org.id} />
                  </TableCell>
                  <TableCell align="right" sx={{ paddingRight: 0 }}>
                    <MemberActions
                      org_name={org.display_name}
                      user_id={user_id}
                      org_id={org.id}
                      mutate={mutate}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions sx={{ flexDirection: 'row-reverse' }}></CardActions>
    </Card>
  );
};

export default UserOrgsTable;
