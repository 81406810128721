import { useState, useEffect } from 'react';
import useSWRMutation, { MutationFetcher } from 'swr/mutation';
import { useAuth0 } from '@auth0/auth0-react';

import Fetcher, { FetcherFunction } from '../utils/fetcher';

const useMutate = <TData = unknown>(url: string, method: 'POST' | 'DELETE' | 'PUT') => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [fetcher, setFetcher] = useState<FetcherFunction | undefined>(undefined);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(Fetcher)
        .then(f => setFetcher(() => f));
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const { trigger, isMutating } = useSWRMutation([url], fetcher as MutationFetcher, {});

  const customTrigger = async (data: TData, options: Record<string, unknown> = {}) => {
    await trigger(
      {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      },
      options,
    );
  };

  return { trigger: customTrigger, inProgress: isMutating };
};

export default useMutate;
