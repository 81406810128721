import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import useInvitations from 'hooks/useInvitations';
import InviteMembersButton from 'components/InviteMembersButton';
import DeleteInvitationButton from 'components/DeleteInvitationButton';
import IInvitation from 'interfaces/Invitation';

interface Props {
  org_id: string;
}

const InvitationsTable = ({ org_id }: Props) => {
  const { invitations, isLoading, error, mutate } = useInvitations(org_id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <>
      <InviteMembersButton org_id={org_id} mutate_invitations={mutate} />
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Expires at</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((invitation: IInvitation) => (
              <TableRow
                key={invitation.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{invitation.invitee.email}</TableCell>
                <TableCell>{invitation.created_at}</TableCell>
                <TableCell>{invitation.expires_at}</TableCell>
                <TableCell>{invitation.inviter.name}</TableCell>
                <TableCell align="right">
                  <DeleteInvitationButton
                    org_id={org_id}
                    invitation_id={invitation.id}
                    mutateInvitations={mutate}
                    invitee_email={invitation.invitee.email}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InvitationsTable;
