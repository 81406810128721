import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useInvitations = (org_id: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${org_id}/invitations`;
  const { data, error, isLoading, mutate } = useSWR(encodeURI(url));

  return {
    invitations: data,
    error,
    isLoading,
    mutate,
  };
};

export default useInvitations;
