import { useState } from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';

import ConfirmationDialog from './ConfirmationDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useInvitationsDelete from 'hooks/useInvitationsDelete';

interface Props {
  org_id: string;
  invitation_id: string;
  mutateInvitations: () => void | Promise<void>;
  invitee_email: string;
}

const DeleteInvitationButton = ({
  org_id,
  invitation_id,
  mutateInvitations,
  invitee_email,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger } = useInvitationsDelete(org_id, invitation_id);
  const [failed, setFailed] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const deleteInvitation = () => {
    trigger({})
      .then(() => {
        mutateInvitations();
        setDialogOpen(false);
      })
      .catch(e => {
        setFailed(true);
        console.error(e);
      });
  };

  return (
    <>
      <IconButton
        color="error"
        title="Delete invitation"
        aria-label="Delete invitation"
        size="small"
        onClick={handleDialogOpen}>
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>
      <ConfirmationDialog
        title="Delete invitation"
        description={`Are you sure you want to delete this invitation for ${invitee_email}?`}
        confirmationText="Delete"
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        confirmAction={deleteInvitation}
      />
      <Snackbar open={failed} autoHideDuration={5000}>
        <Alert severity="error">Whoops! Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default DeleteInvitationButton;
