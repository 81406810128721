import { useNavigate } from 'react-router-dom';

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Group, Work, Shield } from '@mui/icons-material';

interface navItem {
  index: number;
  icon: JSX.Element;
  label: string;
  route: string;
}

const navItems: navItem[] = [
  {
    index: 0,
    icon: <Group />,
    label: 'Users',
    route: 'users',
  },
  {
    index: 1,
    icon: <Work />,
    label: 'Organizations',
    route: 'organizations',
  },
  {
    index: 2,
    icon: <Shield />,
    label: 'Roles',
    route: 'roles',
  },
];

const NavbarList = () => {
  const navigate = useNavigate();

  return (
    <List>
      {navItems.map(item => (
        <ListItem key={item.index} onClick={() => navigate(item.route)} disablePadding>
          <ListItemButton>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default NavbarList;
