import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useUserDelete = (userID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/users/${userID}`;

  const { trigger, inProgress } = useMutate(encodeURI(url), 'DELETE');

  return { trigger, inProgress };
};

export default useUserDelete;
