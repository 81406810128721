import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridEventListener,
  GridColDef,
  GridToolbar,
  GridCellParams,
  gridClasses,
} from '@mui/x-data-grid';
import useOrgMembers from '../hooks/useOrgMembers';
import OrgMemberRoles from './user/OrgMemberRoles';
import MemberActions from './MemberActions';

interface MemberRow {
  name: string;
  email: string;
  user_id: string;
  roles: string[];
}

interface Props {
  org_id: string;
}

const MembersTable = ({ org_id }: Props) => {
  const navigate = useNavigate();
  const { members, isLoading, error, mutate } = useOrgMembers(org_id);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 0.75 },
    { field: 'email', headerName: 'Email', flex: 0.75 },
    { field: 'user_id', headerName: 'User ID', flex: 0.75 },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 1,
      renderCell: params => (
        <OrgMemberRoles
          user_id={params.row.user_id}
          org_id={org_id}
          fetched_roles={params.row.roles}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.25,
      renderCell: params => (
        <MemberActions
          org_id={org_id}
          user_id={params.row.user_id}
          org_name={org_id}
          mutate={mutate}
        />
      ),
    },
  ];

  const [paginationModel, setPaginationModel] = useState(() => {
    const savedModel = localStorage.getItem('membersTablePaginationModel');
    return savedModel ? JSON.parse(savedModel) : { page: 0, pageSize: 20 };
  });

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  const visitUser: GridEventListener<'cellClick'> = (params: GridCellParams) => {
    if (['user_id', 'email', 'name'].includes(params.field)) {
      navigate(`/users/${params.id}`);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        autoHeight
        sx={{
          '.MuiDataGrid-row': { cursor: 'pointer' },
          [`& .${gridClasses.cell}`]: {
            display: 'flex',
            alignItems: 'center',
          },
          maxWidth: '99%',
        }}
        columns={columns}
        rows={members as MemberRow[]}
        loading={isLoading}
        getRowId={(row: MemberRow) => row.user_id}
        pagination
        paginationMode="client"
        paginationModel={paginationModel}
        onPaginationModelChange={newModel => {
          setPaginationModel(newModel);
          localStorage.setItem('membersTablePaginationModel', JSON.stringify(newModel));
        }}
        pageSizeOptions={[10, 20, 30, 40, 50]}
        onCellClick={visitUser}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
};

export default MembersTable;
