import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useRoleDelete = (orgID: string, userID: string, roleID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${orgID}/members/${userID}/roles/${roleID}`;

  const { trigger, inProgress } = useMutate(encodeURI(url), 'DELETE');

  return { trigger, inProgress };
};

export default useRoleDelete;
