import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  LinearProgress,
  Toolbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useBulkUserDelete from 'hooks/useBulkUserDelete';
import ErrorDialog from './ErrorDialog';

import { MutatorCallback } from 'swr';

interface Props {
  users: Array<{ id: string; name?: string }>;
  mutateData: MutatorCallback;
}

const DeleteUsersButton: React.FC<Props> = ({ users, mutateData }) => {
  const [open, setOpen] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [operationStatus, setOperationStatus] = useState<'completed' | 'failed' | null>(null);

  const userIds = users.map(user => user.id);

  const {
    trigger: deleteUsers,
    inProgress,
    progress,
    failedUserIds,
    currentUserId,
  } = useBulkUserDelete(userIds);

  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    if (!inProgress) {
      setOpen(false);
    }
  };

  const handleDelete = async () => {
    setOperationStatus(null);
    try {
      await deleteUsers();

      if (failedUserIds.length > 0) {
        throw new Error(`Failed to delete ${failedUserIds.length} users`);
      }
      mutateData();
      setOperationStatus('completed');
      setOpen(false);
    } catch (error) {
      console.error('Error during deletion:', error);
      setErrorMessage(
        error instanceof Error ? error.message : 'An error occurred while deleting users.',
      );
      setOpenErrorDialog(true);
      setOperationStatus('failed');
    }
  };

  const getCurrentUserName = () => {
    if (!currentUserId) return '';
    const user = users.find(u => u.id === currentUserId);
    return user?.name || currentUserId;
  };

  return (
    <>
      <Toolbar disableGutters>
        <Button
          variant="outlined"
          title="Delete users (even if they belong to an organization)"
          startIcon={<DeleteIcon />}
          onClick={openDialog}
          disabled={users.length === 0}
          color="secondary">
          Delete Users
        </Button>
      </Toolbar>

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Delete Users</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {users.length} users?
          </DialogContentText>
          <ul style={{ maxHeight: '150px', overflowY: 'auto', padding: 0, listStyleType: 'none' }}>
            {users.map(user => (
              <li
                key={user.id}
                style={{
                  margin: '8px 0',
                  opacity: inProgress && user.id === currentUserId ? 0.5 : 1,
                }}>
                <strong>{user.id}</strong> ({user.name})
                {inProgress && user.id === currentUserId && ' (Deleting...)'}
              </li>
            ))}
          </ul>
          {inProgress && (
            <>
              <LinearProgress variant="determinate" value={progress * 100} sx={{ my: 2 }} />
              <DialogContentText variant="caption">
                Currently deleting: {getCurrentUserName()}
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} disabled={inProgress}>
            Cancel
          </Button>
          <Button onClick={handleDelete} disabled={inProgress} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={operationStatus === 'completed'}
        autoHideDuration={5000}
        onClose={() => setOperationStatus(null)}>
        <Alert severity="success">
          {failedUserIds.length === 0
            ? 'All users deleted successfully.'
            : `Successfully deleted ${users.length - failedUserIds.length} out of ${users.length} users.`}
        </Alert>
      </Snackbar>

      <Snackbar
        open={operationStatus === 'failed'}
        autoHideDuration={5000}
        onClose={() => setOperationStatus(null)}>
        <Alert severity="error">{errorMessage || 'Failed to delete users.'}</Alert>
      </Snackbar>

      <ErrorDialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default DeleteUsersButton;
