import { useAuth0 } from '@auth0/auth0-react';
import { Button, IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button variant="outlined" onClick={() => loginWithRedirect()}>
      LOGIN
    </Button>
  );
};

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <IconButton
      aria-label="logout"
      title="Logout"
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      <LogoutIcon />
    </IconButton>
  );
};

export { LoginButton, LogoutButton };
