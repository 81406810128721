import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import NavbarList from './NavbarItems';
import Profile from './Profile';

const drawerWidth = 240;

function Navbar() {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left">
      <Profile />

      <Divider />
      <NavbarList />
    </Drawer>
  );
}

export default Navbar;
