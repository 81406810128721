import { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  TextField,
  FormHelperText,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';

import { orgCreationSchema, OrgCreationSchemaType } from 'interfaces/Organization';
import IAvailableConnection from 'interfaces/Connection';
import useOrgPost from 'hooks/useOrgPost';
import useAvailableConnections from 'hooks/useAvailableConnections';
import ErrorDialog from './ErrorDialog';
import { FetcherError } from '../utils/fetcher';

interface Props {
  mutate_organizations: () => void | Promise<void>;
}

const CreateOrgButton = ({ mutate_organizations }: Props) => {
  const [open, setOpen] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { trigger, inProgress } = useOrgPost();
  const { availableConnections } = useAvailableConnections();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      display_name: '',
      connections: ['google-oauth2', 'Username-Password-Authentication', 'windowslive'],
    },
    resolver: zodResolver(orgCreationSchema),
  });

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const onSubmit = useCallback(
    async (values: OrgCreationSchemaType) => {
      try {
        await trigger(
          { ...values },
          {
            onSuccess: () => {
              mutate_organizations();
              closeDialog();
              setErrorMessage('');
            },
            onError: (error: unknown) => {
              if (error instanceof FetcherError) {
                // error.message is the same for all errors, see fetcher.tsx
                setErrorMessage(
                  error.bodyText + ' (HTTP ' + error.status + ': ' + error.statusText + ')',
                );
                setOpenErrorDialog(true);
              } else {
                setErrorMessage('Unexpected error!');
                setOpenErrorDialog(true);
              }
            },
          },
        );
      } catch (error) {
        // Do nothing, error handled earlier with onError
        console.info(error);
      }
    },
    [trigger, mutate_organizations, handleCloseErrorDialog],
  );

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Toolbar disableGutters>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={openDialog}>
          Create Organization
        </Button>
      </Toolbar>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Create a new organization</DialogTitle>
        <DialogContent sx={{ minWidth: '40ch', maxWidth: '40ch' }}>
          <form id="create-org-form" onSubmit={handleSubmit(onSubmit)}>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="name"
                    label="Name"
                    helperText="Human readable identifier"
                    error={!!errors.name}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}>
              <Controller
                name="display_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    id="display_name"
                    label="Display Name"
                    helperText="Friendly name to display to users"
                    error={!!errors.display_name}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box
              component="div"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
              }}>
              <Controller
                name="connections"
                control={control}
                defaultValue={['google-oauth2', 'Username-Password-Authentication', 'windowslive']}
                render={({ field }) => (
                  <FormControl fullWidth error={!!errors.connections}>
                    <InputLabel id="connections-label">Connections</InputLabel>
                    <Select
                      {...field}
                      onChange={event => field.onChange(event.target.value)}
                      value={field.value || []}
                      labelId="connections-label"
                      id="connections"
                      multiple
                      label="Connections"
                      renderValue={selected =>
                        (selected as string[])
                          .map(name => {
                            const selectedConnection = availableConnections.find(
                              (conn: IAvailableConnection) => conn.name === name,
                            );
                            return selectedConnection ? selectedConnection.name : name;
                          })
                          .join(', ')
                      }>
                      {availableConnections.map((connection: IAvailableConnection) => (
                        <MenuItem key={connection.id} value={connection.name}>
                          {connection.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Select available login methods</FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            form="create-org-form"
            type="submit"
            loading={inProgress}
            disabled={!isValid}>
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ErrorDialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default CreateOrgButton;
