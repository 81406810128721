import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgMembersPost = (orgID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${orgID}/members`;

  const { trigger, inProgress } = useMutate(url, 'POST');

  return { trigger, inProgress };
};

export default useOrgMembersPost;
