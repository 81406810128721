import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgDelete = (org_id: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${org_id}`;

  const { trigger, inProgress } = useMutate(url, 'DELETE');

  return { trigger, inProgress };
};

export default useOrgDelete;
