import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useInvitationsPost = (orgID: string, invitationID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${orgID}/invitations/${invitationID}`;

  const { trigger, inProgress } = useMutate(url, 'DELETE');

  return { trigger, inProgress };
};

export default useInvitationsPost;
