import React from 'react';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Toolbar,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import useOrgMembersPost from 'hooks/useOrgMembersPost';
import useOrgs from 'hooks/useOrgs';
import useRoles from 'hooks/useRoles';

import IOrganization from 'interfaces/Organization';
import IRole from 'interfaces/Role';

interface Props {
  users: GridRowSelectionModel;
  mutation?: () => void | Promise<void>;
}

const AddUsersToOrgButton = ({ users, mutation }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [organization, setOrganization] = React.useState('');
  const [role, setRole] = React.useState('');
  const [completed, setCompleted] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const { trigger, inProgress } = useOrgMembersPost(organization);
  const { orgs } = useOrgs();
  const { roles } = useRoles();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const dismissAlert = () => {
    setCompleted(false);
    setFailed(false);
  };

  const updateOrganization = (event: SelectChangeEvent) => {
    setOrganization(event.target.value);
  };

  const updateRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  // TODO: Display report message
  const submitRequest = () => {
    trigger({
      roleID: role,
      users: users,
    })
      .then(() => {
        setCompleted(true);
        if (typeof mutation !== 'undefined') {
          mutation();
        }
        closeDialog();
      })
      .catch(e => {
        console.error(e);
        setFailed(true);
        closeDialog();
      });
  };

  // TODO: Better handling of roles and organizations in case of exception or loading
  return (
    <>
      <Toolbar disableGutters>
        <Button
          variant="outlined"
          title="Add users to organization"
          startIcon={<GroupAddIcon />}
          disabled={users.length === 0}
          onClick={openDialog}>
          Add to Organization
        </Button>
      </Toolbar>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Add users to organization</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select an organization and a role to add the following users:
          </DialogContentText>
          <ul>
            {users.map(user => (
              <li key={user}>{user}</li>
            ))}
          </ul>
          <Stack direction="row" spacing={2}>
            {orgs !== undefined && (
              <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel id="organization-label">Organization</InputLabel>
                <Select
                  labelId="organization-label"
                  id="organization-select"
                  value={organization}
                  label="Organization"
                  onChange={updateOrganization}>
                  {orgs.map((org: IOrganization) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {roles !== undefined && (
              <FormControl sx={{ m: 1, width: '100%' }}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role-select"
                  value={role}
                  label="Role"
                  onChange={updateRole}>
                  {roles.map((role: IRole) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            loading={inProgress}
            onClick={submitRequest}
            disabled={organization.length === 0}>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar open={completed} autoHideDuration={5000} onClose={dismissAlert}>
        <Alert severity="success">Request completed succesfully.</Alert>
      </Snackbar>
      <Snackbar open={failed} autoHideDuration={5000} onClose={dismissAlert}>
        <Alert severity="error">Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default AddUsersToOrgButton;
