import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridEventListener, GridToolbar } from '@mui/x-data-grid';
import useOrgs from 'hooks/useOrgs';
import CreateOrgButton from 'components/CreateOrgButton';
import IOrganization from 'interfaces/Organization';

const OrgsPage = () => {
  // Navigation
  const navigate = useNavigate();

  // Datagrid
  const columns = [
    { field: 'display_name', headerName: 'Display name', width: 300 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'id', headerName: 'Org ID', width: 300 },
    {
      field: 'metadata.CUI_ORG_UUID',
      valueGetter: (row: unknown, value: IOrganization) => value?.metadata?.CUI_ORG_UUID,
      headerName: 'CUI UUID',
      width: 300,
    },
  ];

  const [paginationModel, setPaginationModel] = useState(() => {
    const savedModel = localStorage.getItem('orgsPagePaginationModel');
    return savedModel ? JSON.parse(savedModel) : { page: 0, pageSize: 20 };
  });

  const { orgs, error, isLoading, mutate } = useOrgs();

  const visitOrg: GridEventListener<'rowClick'> = params => {
    navigate(`/organizations/${params.id}`);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Box sx={{ width: '100%', mb: '30px' }}>
      <CreateOrgButton mutate_organizations={mutate} />
      <DataGrid
        autoHeight
        sx={{ '.MuiDataGrid-row': { cursor: 'pointer' }, maxWidth: '99%' }}
        columns={columns}
        rows={orgs}
        loading={isLoading}
        pagination
        paginationMode="client"
        paginationModel={paginationModel}
        onPaginationModelChange={newModel => {
          setPaginationModel(newModel);
          localStorage.setItem('orgsPagePaginationModel', JSON.stringify(newModel));
        }}
        pageSizeOptions={[10, 20, 30, 40, 50]}
        onRowClick={visitOrg}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
};

export default OrgsPage;
