import useSWR from 'swr';
import IRole from '../interfaces/Role';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgMemberRoles = (
  org_id: string,
  user_id: string,
  fetched_roles: IRole[] | undefined = undefined,
) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${org_id}/members/${user_id}/roles`;
  const options = fetched_roles === undefined ? {} : { fallbackData: fetched_roles };
  const { data, error, isLoading, mutate } = useSWR(encodeURI(url), options);
  return {
    roles: data,
    error,
    isLoading,
    mutate,
  };
};

export default useOrgMemberRoles;
