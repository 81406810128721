import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import UserCard from '../../components/user/UserCard';
import UserOrgsTable from '../../components/user/UserOrgsTable';
import UserRolesTable from '../../components/user/UserRolesTable';

const UserPage = () => {
  const params = useParams();
  const user_id: string = String(params.user_id);

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3}>
        <Grid size={{ xl: 3 }}>
          <UserCard user_id={user_id} />
        </Grid>
        <Grid container spacing={3} size={{ xl: 9 }}>
          <Grid size={{ xs: 12 }}>
            <UserOrgsTable user_id={user_id} />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <UserRolesTable user_id={user_id} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPage;
