import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useUserRolesPost = (userID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/users/${userID}/roles`;

  const { trigger, inProgress } = useMutate(encodeURI(url), 'POST');

  return { trigger, inProgress };
};

export default useUserRolesPost;
