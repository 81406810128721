import { z } from 'zod';

export default interface IOrganization {
  id: string;
  name: string;
  display_name: string;
  metadata?: { CUI_ORG_UUID?: string };
}

export const orgCreationSchema = z.object({
  name: z.string().min(1, { message: 'Org name is required' }),
  display_name: z.string().min(1, { message: 'Friendly org name is required' }),
  connections: z.array(z.string()),
});

export type OrgCreationSchemaType = z.infer<typeof orgCreationSchema>;
