import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface Props {
  title: string;
  description: string | React.ReactNode;
  color?: ButtonProps['color'];
  confirmationText: string;
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  confirmAction: () => void;
}

const ConfirmationDialog = ({
  title,
  description,
  color = 'secondary',
  confirmationText,
  isOpen,
  setOpen,
  confirmAction,
}: Props) => {
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={confirmAction} autoFocus color={color}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
