import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useUserRoles = (user_id: string) => {
  const url = `${REACT_APP_API_BASE_URL}/users/${user_id}/roles`;
  const { data, error, isLoading, mutate } = useSWR(encodeURI(url));

  return {
    roles: data,
    error,
    isLoading,
    mutate,
  };
};

export default useUserRoles;
