import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgPost = () => {
  const url = `${REACT_APP_API_BASE_URL}/organizations`;

  const { trigger, inProgress } = useMutate(url, 'POST');

  return { trigger, inProgress };
};

export default useOrgPost;
