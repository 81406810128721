import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Shield from '@mui/icons-material/Shield';

import useRoles from 'hooks/useRoles';
import IRole from 'interfaces/Role';
import RoleTableRow from 'components/roles/RoleTableRow';

const RolesPage = () => {
  const { roles } = useRoles();

  return (
    <Card>
      <CardHeader avatar={<Shield />} title="Roles & Permissions" />
      <Divider />
      <CardContent>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Role</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role: IRole) => (
              <RoleTableRow key={role.id} role={role} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default RolesPage;
