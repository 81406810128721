import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useRolePermissions from '../../hooks/useRolePermissions';
import IPermission from '../../interfaces/Permission';

interface Props {
  role_id: string;
}

const RolePermissionsTable = ({ role_id }: Props) => {
  const { permissions, isLoading, error } = useRolePermissions(role_id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Table size="small" aria-label="permissions" sx={{ td: { border: 0 } }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: 0 }} />
            <TableCell>Server</TableCell>
            <TableCell>Permission</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>API audience</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions.map((permission: IPermission, index: number) => (
            <TableRow key={index}>
              <TableCell />
              <TableCell>{permission.resource_server_name}</TableCell>
              <TableCell>{permission.permission_name}</TableCell>
              <TableCell>{permission.description}</TableCell>
              <TableCell>{permission.resource_server_identifier}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RolePermissionsTable;
