import { useState, useEffect } from 'react';
import { SWRConfig } from 'swr';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import Fetcher from './utils/fetcher';
import { FetcherFunction } from './utils/fetcher';

function SWRProvider() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [fetcher, setFetcher] = useState<null | FetcherFunction>(null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(Fetcher)
        .then(f => setFetcher(() => f));
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!fetcher) {
    return null; // or some loading indicator
  }

  const options = {
    fetcher,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  };

  return (
    <SWRConfig value={options}>
      <Outlet />
    </SWRConfig>
  );
}

export default SWRProvider;
