import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrg = (org_id: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${org_id}`;
  const { data, error, isLoading, mutate } = useSWR(url);

  return {
    org: data,
    error,
    isLoading,
    mutate,
  };
};

export default useOrg;
