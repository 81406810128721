import { createBrowserRouter, Navigate } from 'react-router-dom';
import Auth0ProviderWithNavigate from '../auth/Auth0Provider';
import SWRProvider from '../SWRProvider';
import { AuthenticationGuard } from '../auth/authenticationGuard';
import ProtectedRoutes from './protectedRoutes';
import UsersPage from '../pages/users/UsersPage';
import UserPage from '../pages/users/UserPage';
import OrgsPage from '../pages/orgs/OrgsPage';
import OrgPage from '../pages/orgs/OrgPage';
import RolesPage from '../pages/roles/RolesPage';

export const Routes = createBrowserRouter([
  {
    element: <Auth0ProviderWithNavigate />,
    children: [
      {
        element: <AuthenticationGuard component={ProtectedRoutes} />,
        children: [
          {
            element: <SWRProvider />,
            children: [
              { path: '/', element: <Navigate to="/users" replace /> },
              { path: 'users', element: <UsersPage /> },
              { path: 'users/:user_id', element: <UserPage /> },
              { path: 'organizations', element: <OrgsPage /> },
              { path: 'organizations/:org_id', element: <OrgPage /> },
              { path: 'roles', element: <RolesPage /> },
            ],
          },
        ],
      },
    ],
  },
]);
