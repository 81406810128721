import useMutate from './useMutate';

const { REACT_APP_API_BASE_URL } = process.env;

const useInvitationsPost = (orgID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${orgID}/invitations`;

  const { trigger, inProgress } = useMutate(url, 'POST');

  return { trigger, inProgress };
};

export default useInvitationsPost;
