import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useConnections = (orgID: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${orgID}/connections`;
  const { data, error, isLoading } = useSWR(url);

  return {
    connections: data,
    error,
    isLoading,
  };
};

export default useConnections;
