import { z } from 'zod';

const inviteeSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  roles: z.string().array().optional(),
  connection_id: z.string().optional(),
});

export const invitationRequestSchema = z.object({
  client_id: z.string().min(1, { message: 'Application is required' }),
  invitees: inviteeSchema.array(),
});

export type InvitationRequestSchemaType = z.infer<typeof invitationRequestSchema>;
