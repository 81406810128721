import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useAvailableConnections = () => {
  const url = `${REACT_APP_API_BASE_URL}/connections/available`;
  const { data, error, isLoading } = useSWR(url);

  return {
    availableConnections: data,
    error,
    isLoading,
  };
};

export default useAvailableConnections;
