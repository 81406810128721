import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgs = () => {
  const url = `${REACT_APP_API_BASE_URL}/organizations`;
  const { data, error, isLoading, mutate } = useSWR(url);

  return {
    orgs: data,
    error,
    isLoading,
    mutate,
  };
};

export default useOrgs;
