import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface BulkDeleteResponse {
  completedUserIds: string[];
  failedUserIds: string[];
}

const useBulkUserDelete = (userIds: string[]) => {
  const [inProgress, setInProgress] = useState(false);
  const [completedUserIds, setCompletedUserIds] = useState<string[]>([]);
  const [failedUserIds, setFailedUserIds] = useState<string[]>([]);
  const [currentUserIndex, setCurrentUserIndex] = useState<number | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const progress = completedUserIds.length / userIds.length;

  const deleteUser = async (userId: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/users/${encodeURIComponent(userId)}`;
    const token = await getAccessTokenSilently();

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to delete user ${userId}`);
    }
  };

  const trigger = useCallback(async (): Promise<BulkDeleteResponse> => {
    if (inProgress || userIds.length === 0) {
      return { completedUserIds: [], failedUserIds: [] };
    }

    setInProgress(true);
    setCompletedUserIds([]);
    setFailedUserIds([]);
    setCurrentUserIndex(0);

    for (let i = 0; i < userIds.length; i++) {
      setCurrentUserIndex(i);
      try {
        await deleteUser(userIds[i]);
        setCompletedUserIds(prev => [...prev, userIds[i]]);
      } catch (error) {
        console.error(`Error deleting user with ID ${userIds[i]}`, error);
        setFailedUserIds(prev => [...prev, userIds[i]]);
      }
    }

    setCurrentUserIndex(null);
    setInProgress(false);

    return {
      completedUserIds: userIds.filter(id => !failedUserIds.includes(id)),
      failedUserIds: [...failedUserIds],
    };
  }, [userIds, inProgress]);

  return {
    trigger,
    inProgress,
    progress,
    completedUserIds,
    failedUserIds,
    currentUserId: currentUserIndex !== null ? userIds[currentUserIndex] : null,
  };
};

export default useBulkUserDelete;
