import { useAuth0 } from '@auth0/auth0-react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { LogoutButton } from '../../components/Authentication';

const Profile = () => {
  const { user } = useAuth0();

  return (
    <Card sx={{ border: 'none', borderRadius: 0, boxShadow: 'unset' }}>
      <CardHeader title="User Management" />
      <CardContent>
        <Typography gutterBottom variant="body1" component="div">
          {user?.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {user?.email}
        </Typography>
      </CardContent>
      <CardActions sx={{ flexDirection: 'row-reverse' }}>
        <LogoutButton />
      </CardActions>
    </Card>
  );
};

export default Profile;
