import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';

const Auth0ProviderWithNavigate = () => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const redirectUri = window.location.origin;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        scope: 'openid profile email read:resources update:resources',
      }}
      onRedirectCallback={onRedirectCallback}>
      <Outlet />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
