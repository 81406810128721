interface Options {
  arg?: {
    headers?: Record<string, string>;
    method?: string;
    body?: string;
  };
}

export class FetcherError extends Error {
  status?: number;
  statusText?: string;
  bodyText?: string;

  constructor(message: string, status?: number, statusText?: string, bodyText?: string) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.bodyText = bodyText;
    Object.setPrototypeOf(this, FetcherError.prototype); // Needed for extending built-in Error
  }
}

export type FetcherFunction = <T = unknown>(url: string, options?: Options) => Promise<T>;

function Fetcher(accessToken: string): FetcherFunction {
  const authHeader = { Authorization: `Bearer ${accessToken}` };

  const fetcher = async (url: string, { arg: options = {} }: Options = { arg: {} }) => {
    const response = await fetch(url, {
      ...options,
      headers: { ...options.headers, ...authHeader },
    });

    // A better approach here would be to not have the if statement and return `response` instead of `response.json()`.
    // This is because the former would include status code, text, etc, whereas the latter only contains the body.
    // However, this would require lots of testing and refactoring of the rest of the codebase, hence this alternative.
    if (!response.ok) {
      throw new FetcherError(
        'Network response was not ok',
        response.status,
        response.statusText,
        (await response.json())?.error,
      );
    }
    return response.json();
  };

  return fetcher;
}

export default Fetcher;
