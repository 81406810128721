import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useOrgMembers = (org_id: string) => {
  const url = `${REACT_APP_API_BASE_URL}/organizations/${org_id}/members`;
  const { data, error, isLoading, mutate } = useSWR(url);

  return {
    members: data,
    error,
    isLoading,
    mutate,
  };
};

export default useOrgMembers;
