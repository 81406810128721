import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import App from '../App';

const ProtectedRoutes = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <App /> : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;
