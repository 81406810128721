import { CircularProgress } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import useUser from 'hooks/useUser';
import UserAvatar from 'components/user/UserAvatar';

interface Props {
  user_id: string;
}

const UserCard = ({ user_id }: Props) => {
  const { user, isLoading, error } = useUser(user_id);

  if (isLoading) {
    return <CircularProgress />;
  }
  if (error != null) {
    console.log(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Card>
      {user && (
        <CardHeader
          avatar={<UserAvatar name={user.name} image={user?.picture} />}
          title={user?.name}
          subheader={user?.email}
        />
      )}
      <Divider />
      <CardContent>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemText primary="ID" secondary={user?.user_id} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Created at" secondary={user?.created_at} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Last login" secondary={user?.last_login} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Logins count" secondary={user?.logins_count} />
          </ListItem>
        </List>
      </CardContent>
      <CardActions sx={{ flexDirection: 'row-reverse' }}></CardActions>
    </Card>
  );
};

export default UserCard;
