import useSWR from 'swr';

const { REACT_APP_API_BASE_URL } = process.env;

const useClients = () => {
  const url = `${REACT_APP_API_BASE_URL}/clients`;
  const { data, error, isLoading } = useSWR(url);

  return {
    clients: data,
    error,
    isLoading,
  };
};

export default useClients;
