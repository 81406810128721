export interface GroupedItems<T> {
  [key: string]: T[];
}

export function groupBy<T>(array: T[], key: keyof T): GroupedItems<T> {
  return array.reduce((result: GroupedItems<T>, currentValue: T) => {
    const groupByKey = currentValue[key] as string;
    if (!result[groupByKey]) {
      result[groupByKey] = [];
    }
    result[groupByKey].push(currentValue);
    return result;
  }, {} as GroupedItems<T>);
}
