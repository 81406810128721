import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import IRole from '../../interfaces/Role';
import useUserRolesPost from '../../hooks/useUserRolesPost';
import useRoles from '../../hooks/useRoles';

interface Props {
  user_id: string;
  mutateRoles: () => void | Promise<void>;
}

const AddRolesToUserButton = ({ user_id, mutateRoles }: Props) => {
  const [open, setOpen] = useState(false);
  const { roles } = useRoles();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const { trigger, inProgress } = useUserRolesPost(user_id);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const updateSelectedRoles = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const submitRequest = () => {
    trigger({ roles: selectedRoles })
      .then(() => {
        mutateRoles();
        closeDialog();
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        aria-label="Add roles to user"
        title="Add roles to user"
        startIcon={<AddModeratorIcon />}
        onClick={openDialog}>
        Assign roles
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Add roles to user</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: '15px' }}>Select roles:</DialogContentText>
          {roles !== undefined && (
            <FormControl sx={{ m: 1, width: '100%' }}>
              <InputLabel id="role-label">Roles</InputLabel>
              <Select
                labelId="role-label"
                id="role-select"
                multiple
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                value={selectedRoles}
                onChange={updateSelectedRoles}
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => {
                      const role = roles.find((r: IRole) => r.id === value);
                      return <Chip key={value} label={role ? role.name : value} />;
                    })}
                  </Box>
                )}
                label="Roles">
                {roles.map((role: IRole) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <LoadingButton
            loading={inProgress}
            onClick={submitRequest}
            disabled={selectedRoles.length === 0}>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRolesToUserButton;
